import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import iphone from "../assets/images/features/iphonedemo2.png"
import mockup from "../assets/images/features/Mockup4.png"

const Three = () => (
    <div className="flex flex-row bg-white h-full items-center justify-center" id="aiodashboard">
        <div className="grid grid-cols-8 gap-0 w-full h-full">
            <div className="col-start-1 col-span-3 bg-white w-full hidden md:flex justify-center items-center">
                <img src={mockup} />
            </div>
            <div className="col-start-1 md:col-start-4 md:col-span-5 col-span-8 bg-primary-400 w-full flex flex-col justify-center items-center">
                <div className="flex flex-col w-[20rem] py-10 lg:w-[30rem] gap-6 text-white">
                        <span className="font-semibold text-primary-200 text-2xl">
                            AIO Dashboard
                        </span>
                        <span className="font-extrabold text-3xl">
                            Visualize an entire project on one page !
                        </span>
                        <span className="mt-8">
                            <p> 
                            The dashboard is a unique page per project that describes every key aspect. You may find the roadmap, the tokenomics graph, ICO related information, the main socials, founders names and much more. As Keyvian gets developed, more features will be added, in order to create the most advanced project dashboard in the cryptosphere. <br/>
                                &nbsp; <br/>
                                No more losing time searching for the tokenomics, the roadmap, etc. They will all be accessible with the touch of one button, only in the Keyvian app !  <br/> 
                                &nbsp; <br/>
                                Our vision goes beyond the purely informative utility of this feature, as we would like our users to take part in tomorrow's dashboard. To be clear, each new addition in the dashboard will be voted by Keyvian token holders in community votes.
                            </p>
                        </span>
                </div>
            </div>
        </div>
    </div>
)

export default Three
