import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import logo from "../assets/images/Logo/keyvian_logo.png"
import appstore from "../assets/images/social/appstore.png"
import playstore from "../assets/images/social/playstore.png"

const Ten = () => (
    <div className="flex flex-col bg-white h-full justify-center items-center">
        <div className="flex flex-col bg-white items-center justify-center">
            <div className="flex flex-col gap-4 justify-center items-center">
                <img src={logo} className="w-20" />
                <span className="text-xl font-bold">
                    Feel safe on your journey!
                </span>
                <span className="lg:w-full w-3/4">
                    Download the free Keyvian App and take your crypto to the next level.
                </span>
                {/* CODE OF THE "Try Now" BUTTON"*/
                <div className="flex items-center justify-center m-0">
                    <Link className="h-10 relative bg-white text-primary-200 hover:text-white items-center flex justify-center p-6 text-base md:text-2xl rounded-md duration-500 border border-primary-200 hover:bg-primary-200"
                    to="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNiNwTUm5wsy0g0siuOQNth%2FKrypto-App-Design%3Fnode-id%3D2912%253A35964%26scaling%3Dcontain%26page-id%3D11%253A4852%26starting-point-node-id%3D2912%253A34774">
                        Try the App Now
                        <span className="opacity-90 absolute h-4 w-4 -top-2 -left-2 rounded-full bg-primary-200"/>
                        <span className="animate-ping absolute h-4 w-4 -top-2 -left-2 rounded-full bg-primary-200 opacity-90"/>
                    </Link> 
                </div>
                }
                {/* CODE TO DISPLAY THE APP STORE AND PLAY STORE BUTTONS
                <div className="flex flex-row justify-center items-center mt-0 opacity-80">
                    <img src={appstore} className="w-32 cursor-not-allowed" />
                    <img src={playstore} className="w-36 cursor-not-allowed"/>
                </div>
                */}
            </div>
        </div>
    </div>

)

export default Ten
