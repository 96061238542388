import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import rocket from "../assets/images/illustrations/launch.png"
import whitepaper from "../assets/whitepaper.pdf"

const Three = () => (
    <div className="flex md:flex-row flex-col bg-white h-full py-10 items-center justify-center"> 
        <div className="z-40 flex flex-col md:w-1/2 py-10 gap-6 items-center justify-center">
            <span className="w-9/12 md:w-9/12 ">
                <a className="font-bold">Our Mission:</a> <br/>
                The mission of Keyvian is to nurture an environment where individuals can safely invest and where Decentralized Finance communities can thrive.
            </span>
            <span className="w-9/12 md:w-9/12">
                <a className="font-bold">Our Vision:</a> <br/>
                - To empower people to find and maintain financial literacy and freedom <br/>
                - To Restore trust in DeFi and to legitimize it in the eyes of newcomers to the space. <br/>
                - To help people find and maintain financial literacy and freedom. <br/>
            </span>
            <div className="w-9/12 flex items-center flex-col">
            <a
            href={whitepaper}
            target="_blank"
            rel="noopener referrer"
            className={Classes.bigtextButton}
            >
            Read White-paper
            </a>
            </div>
        </div>
        <div className="z-20 absolute flex md:relative md:flex md:w-1/2 h-full w-full p-20 items-center justify-center md:opacity-100 opacity-20">
            <img 
            src={rocket} 
            className="opacity-100 p-6" 
            />
        </div>
    </div>
)

export default Three
