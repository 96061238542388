import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import illus1 from "../assets/images/illustrations/aboutus.png"
import whitepaper from "../assets/whitepaper.pdf"

const AboutUs2 = () => (
    <div className="flex md:grid-cols-2 md:grid items-center justify-center bg-primary-400 h-full py-10 top:500" id="aboutus">
        <div className="hidden md:flex justify-center items-center z-0">
            <img
                src={illus1} 
                className="" 
                />
        </div>
        <div className="z-40 flex flex-col items-center w-full justify-center">
                <div className="flex flex-col w-9/12 gap-6 text-white">
                        <span className="font-semibold text-primary-600 text-2xl">
                            Bridged information
                        </span>
                        <span className="font-extrabold text-3xl">
                            Browse the latest news and projects release in one app. 
                        </span>
                        <span className="mt-8">
                            <p> 
                                With so many news sources reporting on the crypto space, finding the right updates or information can become overwhelming. We take care of this burden for you. <br/>
                                &nbsp; <br/>
                                With Keyvian, you can get access to all your favorite news channels in one interface. Track projects of interest and stay up-to-date on cryptos data and information you need.<br/> 
                                &nbsp; <br/>
                                We’ve got you covered.
                            </p>
                        </span>
                        <a href="/#features" className={classnames(
                            Classes.animatedButton,"border-2 rounded-full w-28 text-center")}>
                            Learn more.
                        </a>
                </div>
        </div>
    </div>
)

export default AboutUs2
