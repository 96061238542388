import * as React from "react"
import ReactDOM from 'react-dom';
import PageLayout from "../components/PageLayout"
import Hero from "../components/1_Hero"
import Partners from "../components/Partners"
import AboutUs from "../components/2_AboutUs"
import Features from "../components/4_Features"
import Three from "../components/3_Features"
import Demo1 from "../components/Demo_1"
import Demo2 from "../components/Demo_2"
import Demo3 from "../components/Demo_3"
import Demo4 from "../components/Demo_4"
import Height from "../components/Roadmap"
import Nine from "../components/Team"
import Ten from "../components/Pre-footer"
import Demo from "../components/demo_iphone"
import Newsletter from "../components/newsletter"



const IndexPage = () => (
    <PageLayout>
        <Hero />
        <div className="w-0 h-32 md:h-12" />
        <div className="z-50" id="waitlist">
            <Newsletter/>
        </div>
        <AboutUs id="aboutus" />
        <Three />
        <Features id="features" />
        <Demo1/>
        <Demo2/>
        <Demo3/>
        <Demo4/>
        <Height id="roadmap" />
        <Nine id="team" />
        <div className="h-20"/>
        <div className="md:hidden mb-10">
            <Ten/>
        </div>
        <Partners />
        <div className="h-24"/>
        <div className="hidden md:flex items-center justify-center mb-10">
            <Ten/>
        </div>
        <div className="h-8"/>
        <div className="h-24"/>
    </PageLayout>
)


export default IndexPage

