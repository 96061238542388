import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import iphone from "../assets/images/features/iphonedemo3.png"
import mockup from "../assets/images/features/Mockup3.png"

const Three = () => (
    <div className="flex flex-row bg-white h-full items-center justify-center" id="personalwallet">
        <div className="grid grid-cols-8 gap-0 w-full h-full">
            <div className="col-start-1 md:col-span-5 col-span-8 bg-primary-700 w-full text-white flex flex-col justify-center items-center">
                <div className="flex flex-col w-[20rem] py-10 lg:w-[30rem] gap-6">
                    <span className="font-semibold text-primary-200 text-2xl">
                        Personal Wallet
                    </span>
                    <span className="font-extrabold text-3xl">
                        Keep track of your crypto in one convenient place.
                    </span>
                    <span className="mt-8">
                        <p>
                            Stay on top of your investments with the Keyvian wallet. <br/>
                            &nbsp; <br/>
                            Keyvian allows you to keep track of all your crypto in a single wallet. The wallet is non-custodial so only you have access and control over your digital assets. <br/> 
                            &nbsp; <br/>
                            Keep an eye on up-to-date pricing indices, measure your portfolio’s performance, and customize your wallet with manual and automatic integrations of holdings. 
                        </p>
                    </span>
                </div>
            </div>
            <div className="col-start-6 col-span-3 bg-white w-full md:flex hidden items-center justify-center">
                <img src={mockup} />
            </div>
        </div>
    </div>
)

export default Three
