import React from "react"
import Classes from "../styles/Classes"
import bsa from "../assets/images/partners/bsa_dark.png"
import blaze from "../assets/images/partners/Blaze_dark.png"
import aleia from "../assets/images/partners/Aleia.png"
import ec from "../assets/images/partners/entrepreneur_club.png"

const linkClassName =
    "transition duration-300 transform hover:scale-110 hover:opacity-75"

const Partners = () => (
    <div
        className="py-10 flex items-center justify-center flex-col gap-4"
        id="partners"
    >
        <span className="text-gray-800 text-xl font-bold">Our Partners :</span>
        <div className="flex flex-col items-center space-y-16 lg:space-y-0 lg:space-x-16 lg:flex-row w-10/12">
            <a
                href="https://bsaepfl.ch"
                target="_blank"
                className={linkClassName}
            >
                <img className="w-80" src={bsa} />
            </a>
            <a
                href="https://www.epfl.ch/innovation/startup/blaze/"
                target="_blank"
                className={linkClassName}
            >
                <img className="w-80" src={blaze} />
            </a>
            <a
                href="https://www.aleia.io/"
                target="_blank"
                className={linkClassName}
            >
                <img className="w-80 p-10" src={aleia} />
            </a>
            <a
                href="https://ec-epfl-unil.ch/"
                target="_blank"
                className={linkClassName}
            >
                <img className="w-80" src={ec} />
            </a>
           
        </div>
    </div>
)

export default Partners
