import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import iphone from "../assets/images/illustrations/iphone_homepage.png"
import mockup from "../assets/images/features/Mockup0.png"
import rect1 from "../assets/images/rectangles/R1.png"
import rect2 from "../assets/images/rectangles/R2.png"
var ReactRotatingText = require('react-rotating-text');

const Hero = () => (
    <div className="">

    <div className="relative grid md:grid-cols-2 grid-cols-1 justify-between w-3/4 md:w-full md:max-w-6xl mx-auto items-center p-4">
        <div className="block md:hidden">
            <img
                src={mockup}
                className="z-10 block w-10/12 relative top-0 mx-auto opacity-100 md:hidden"
            />
            <img
            src={rect1}
            className=" absolute w-10/12 scale-150 scale -top-0 -right-0 opacity-100"
            />
            <img
            src={rect2}
            className=" absolute w-full scale-150 -top-24 -right-0 opacity-100"
            />
        </div>
        <div className="z-10 flex flex-col items-center w-full mt-10 space-y-8 md:w-auto md:items-start md:mt-0">
            <span className="text-4xl md:text-6xl md:text-left text-center font-bold text-black leading-tight md:mt-0">
                All-In-One Crypto Environment.
            </span>
            <span className="w-full text-4xl font-semibold text-black sm:text-left text-center hidden lg:block">
            <ReactRotatingText
                items={[
                    " Exclusive Social Media",
                    " Crypto Wallet",
                    " DeFi News",
                    " Messaging App",
                    " DeFi News",
                    " Crypto Market",
                ]}
                />
            </span>

            
            {/*<Link
                //href="https://t.me/mysite"
                target="_blank"
                to=""
                className={classnames(
                    Classes.animatedButton,
                    "text-white bg-black items-center justify-center flex text-xl font-extrabold rounded-full w-36 h-12 cursor-not-allowed"
                )}
                    >
                        Get Keyvian
            </Link>*/
        }
        </div>
        <div className="flex justify-center md:justify-end relative">
    
            <img 
                src={mockup} 
                className="z-10 hidden relative md:w-10/12 md:block" 
                />
                
            <img
            src={rect1}
            className=" absolute w-10/12 scale-150 scale -top-0 -right-48 opacity-100 hidden md:block"
            />
            <img
            src={rect2}
            className=" absolute w-full scale-150 -top-24 -right-60 opacity-100 hidden md:block"
        />

        </div>

        <img
            src={rect1}
            className=" -z-40 absolute w-7/12 -top-40 -right-48 opacity-100"
        />
        <img
            src={rect1}
            className=" -z-40 absolute w-7/12 -top-40 -right-48 opacity-100"
        />
        <img
            src={rect2}
            className=" -z-40 absolute w-8/12 -top-56 -right-60 opacity-100"
        />
    </div>
    </div>
)

export default Hero
