import { Link } from "gatsby"
import React, { FunctionComponent, useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import Linkedin from "../assets/images/team/linkedin_logo.png"
import allan from "../assets/images/team/allan.png"
import nicolas from "../assets/images/team/nicolas.jpg"
import kf from "../assets/images/team/kf.jpg"
import imaneS from "../assets/images/team/ImaneS.png"
import philibert from "../assets/images/team/Philibert.png"
import banner from "../assets/images/team/empty.png"


const Team = () => (
    <div id="team" className="flex flex-col justify-center items-center bg-primary-400 w-full">
    <div className="flex flex-col gap-4 bg-primary-400 py-10 lg:py-40 md:py-20 w-10/12 lg:w-9/12 text-white items-center">
        
        <div className="text-xl font-bold items-start flex w-full text-primary-200 xl:-mt-16">
            <span>
                The Team
            </span>
        </div>
        <div className="text-2xl flex items-start w-full font-bold text-white mb-2">
            <span>
                Meet our Doers & Builders. 
            </span>
        </div>
            <div className="grid grid-cols-3 justify-between gap-6 sm:gap-10 md:gap-20">
                <CoreTeamMember
                img={nicolas}
                name="Nicolas Filimonov"
                description="Co-founder & CEO"
                link_view="https://www.linkedin.com/in/nicolas-filimonov-b7778b200/"
                />
                <CoreTeamMember
                img={allan}
                name="Allan Abela"
                description="Co-founder & COO"
                link_view="https://www.linkedin.com/in/allan-abela-694751236/"
                />
                <CoreTeamMember
                img={imaneS}
                name="Imane Seghrouchni"
                description="CTO"
                link_view="https://www.linkedin.com/in/imane-s-483772127/"
                />
                <CoreTeamMember
                img={kf}
                name="Kenny Frisella"
                description="Business Analyst"
                link_view="https://www.linkedin.com/in/kenny-frisella-9795b113a/"
                />
                <CoreTeamMember
                img={philibert}
                name="Philibert Wind"
                description="Social Media Manager"
                link_view="https://www.linkedin.com/in/philibert-wind-7120b1236/"
                />
            </div>

            <div className="flex flex-col py-12 text-gray-200 gap-4 text-xs items-start  w-full md:text-lg">
                <span>
                    <a className="font-bold">Developers :</a> 
                </span>


                </div>
            <div className="grid grid-cols-3 justify-between gap-6 sm:gap-10 md:gap-20">
                <TeamMember
                img={banner}
                name="Lucas Brunschwig"
                description="Machine Learning Engineer"
                link_view="https://www.linkedin.com/in/lucas-brunschwig/"
                />
                <TeamMember
                img={banner}
                name="Laurent Brock"
                description="Cybersecurity"
                link_view="https://www.linkedin.com/in/laurent-brock/"
                />
                <TeamMember
                img={banner}
                name="Maxence Calamand"
                description="Blog Webmaster"
                link_view="https://www.linkedin.com/in/maxence-calamand-157035225/"
                />
            </div>

            <div className="flex flex-col py-12 text-gray-200 gap-4 text-xs items-start  w-full md:text-lg">
                <span>
                    <a className="font-bold">Communications :</a> 
                </span>
            </div>

            <div className="grid grid-cols-3 justify-between gap-6 sm:gap-10 md:gap-20">
                <TeamMember
                img={banner}
                name="Jennifer-Joey Ayer"
                description="Community Manager"
                link_view="https://www.linkedin.com/in/jennifer-joey-ayer-b03a711b9/"
                />
                <TeamMember
                img={banner}
                name="Cindy Tang"
                description="Designer"
                link_view="https://www.linkedin.com/in/cindy-tang-b66348222/"
                />
                <TeamMember
                img={banner}
                name="Carlota Imbert"
                description="Designer"
                link_view="https://www.linkedin.com/in/carlota-imbert-833054247/"
                />
            </div>

        </div>
        
        </div>
)

export default Team


const Founders: FunctionComponent<{
    img?: string
    name?: string
    description?: string
    link_view?: string
}> = ({
    img,
    name,
    description,
    link_view,
}) => {
    return (
            <div className="flex group z-40 w-full hover:bg-primary-200 duration-500 items-center justify-center rounded-sm">
            <img src={img} className="z-10 h-full w-full hover:opacity-50 duration-500 object-center rounded-sm">
            </img>
            <div className="flex flex-col absolute justify-center items-center">
            <span className="z-30 opacity-0 group-hover:opacity-100 duration-700 text-xs md:text-base pointer-events-none">
                {name}
            </span>
            <span className="z-30 opacity-0 group-hover:opacity-100 duration-700 text-xs md:text-base pointer-events-none">
                {description}
            </span>
            <a className=" z-40" href={link_view} target="blank" rel="noopener noreferrer">
            <img src={Linkedin} className="z-40 w-[2rem] opacity-0 group-hover:opacity-100 duration-700 mt-2"/>
            </a>
            </div>
        </div>
        )
}

const CoreTeamMember: FunctionComponent<{
    img?: string
    name?: string
    description?: string
    link_view?: string
}> = ({
    img,
    name,
    description,
    link_view,
}) => {
    return (
            <div className="flex group z-40 w-full hover:bg-primary-200 duration-500 items-center justify-center rounded-sm">
            <img src={img} className="z-10 h-full w-full hover:opacity-50 duration-500 object-center rounded-sm">
            </img>
            <div className="flex flex-col absolute justify-center items-center">
            <span className="z-30 opacity-0 group-hover:opacity-100 duration-700 text-[8px] md:text-base md:font-normal font-semibold pointer-events-none">
                {name}
            </span>
            <span className="z-30 opacity-0 group-hover:opacity-100 duration-700 text-[8px] md:text-base pointer-events-none text-center w-4/6 md:w-full">
                {description}
            </span>
            <a className=" z-40" href={link_view} target="blank" rel="noopener noreferrer">
            <img src={Linkedin} className="z-40 w-[1rem] md:w-[2rem] opacity-0 group-hover:opacity-100 duration-700 mt-2"/>
            </a>
            </div>
        </div>
        )
}

const TeamMember: FunctionComponent<{
    img?: string
    name?: string
    description?: string
    link_view?: string
}> = ({
    img,
    name,
    description,
    link_view,
}) => {
    return (
            <div className="flex group z-40 w-full hover:bg-primary-200 duration-500 items-center justify-center rounded-sm">
            <img src={img} className="z-10 h-full w-full hover:opacity-50 duration-500 object-center rounded-sm">
            </img>
            <div className="flex flex-col absolute justify-center items-center">
            <span className="z-30 group-hover:opacity-90 duration-700 text-[8px] md:text-base md:font-normal font-semibold pointer-events-none">
                {name}
            </span>
            <span className="z-30 group-hover:opacity-90 duration-700 text-[8px] md:text-base pointer-events-none text-center w-4/6 md:w-full">
                {description}
            </span>
            <a className=" z-40" href={link_view} target="blank" rel="noopener noreferrer">
            <img src={Linkedin} className="z-40 w-[1rem] md:w-[2rem] opacity-80 duration-700 mt-2"/>
            </a>
            </div>
        </div>
        )
}