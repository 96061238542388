import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import mockup from "../assets/images/features/Mockup1.png"

const Three = () => (
    <div className="flex flex-row bg-white h-full items-center justify-center" id="bridgedinformation">
        <div className="grid grid-cols-8 gap-0 w-full h-full">
            <div className="col-start-1 col-span-8 md:col-span-5 bg-primary-700 w-full flex flex-col justify-center items-center">
                <div className="flex flex-col w-[20rem] lg:w-[30rem] py-10 gap-6 text-white">
                        <span className="font-semibold text-primary-200 text-2xl">
                            Bridged information
                        </span>
                        <span className="font-extrabold text-3xl">
                            Browse the latest news and projects release in one app. 
                        </span>
                        <span className="mt-8">
                            <p> 
                                With so many news sources reporting on the crypto space, finding the right updates or information can become overwhelming. We take care of this burden for you. <br/>
                                &nbsp; <br/>
                                With Keyvian, you can get access to all your favorite news channels in one interface. Track projects of interest and stay up-to-date on cryptos data and information you need.<br/> 
                                &nbsp; <br/>
                                We’ve got you covered.
                            </p>
                        </span>
                </div>
            </div>
            <div className="hidden md:flex col-start-6 col-span-3 bg-white w-full items-center justify-center">
                <img src={mockup}>
                </img>
            </div>
        </div>
    </div>
)

export default Three
