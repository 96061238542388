import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import iphone from "../assets/images/features/iphonedemo2.png"
import mockup from "../assets/images/features/Mockup2.png"

const Three = () => (
    <div className="flex flex-row bg-white h-full items-center justify-center" id="privatemessaging">
        <div className="grid grid-cols-8 gap-0 w-full h-full">
            <div className="col-start-1 col-span-3 bg-white w-full hidden md:flex justify-center items-center">
                <img src={mockup} />
            </div>
            <div className="col-start-1 md:col-start-4 md:col-span-5 col-span-8 bg-primary-400 w-full flex flex-col justify-center items-center">
                <div className="flex flex-col w-[20rem] py-10 lg:w-[30rem] gap-6 text-white">
                        <span className="font-semibold text-primary-200 text-2xl">
                            Private Messaging
                        </span>
                        <span className="font-extrabold text-3xl">
                            Connect and interact with communities and project owners. 
                        </span>
                        <span className="mt-8">
                            <p> 
                                Keyvian is about people and communities. We want to facilitate communication in an honest and secure environment for crypto enthusiasts. <br/>
                                &nbsp; <br/>
                                With Keyvian chat you can join and create community channels, and send 1:1 private messages or group messages.  <br/> 
                                &nbsp; <br/>
                                To help you stay safe from surveilling third parties, we use end-to-end encryption on all of your private messages. We also mitigate risks of scams by using KYC protocols to ensure users authenticity and projects reliability.
                            </p>
                        </span>
                </div>
            </div>
        </div>
    </div>
)

export default Three
