
import { Link } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"

// update de the newsletter registring component
const Demo = () => (
    <div className="flex items-center justify-center w-full relative h-full z-40 bg-transparent">
        <iframe width="800" height="400" src="https://70cd8dc7.sibforms.com/serve/MUIEAOnMalJuWvNRux9UH-prj20y5dh4j6nPPW3HWRz3iFGpkqJ2WnXhrorEUSTbcfXPEyIbBgp6WQ4l3OJwKynoSCEzAv-deGTNewCVqwLUVFdJn8dBrp32wmlueCmtZ3Z1rsYnmUKnMVD7ON8FE1T8eX6oSX0qZ-cZSjewyUidbVC5YC7ikL1beoHo_cI21MOAado8ziRckiZO" frameborder="0" scrolling="auto" allowfullscreen className="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    </div>
)
export default Demo
