import { Link } from "gatsby"
import React, { FunctionComponent, useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import Classes from "../styles/Classes"
import classnames from "classnames"
import feature1 from "../assets/images/features/f1.png"
import feature2 from "../assets/images/features/f2.png"
import feature3 from "../assets/images/features/f3.png"
import feature4 from "../assets/images/features/f4.png"

const AllFeatures = () => (
    <div className="max-w-full">
    <div className="z-20 relative flex flex-col w-full justify-center items-center bg-primary-400 p-24 text-center gap-4"  id="features">
    
        <div className="text-primary-200 md:hidden flex font-bold text-4xl mb-8">
        Features
        </div>
        <div className="text-white hidden md:flex font-bold text-4xl mb-10">
        Features we provide
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-4 gap-20 md:gap-6 w-11/12 mb-10">
            <Feature
            img={feature1}
            name="Bridged Information"
            description="get access to the latest news & projects"
            link_view="/#bridgedinformation"/>
            <Feature
            img={feature2}
            name="Private Messaging"
            description="send private 1:1 & group messages"
            link_view="/#privatemessaging"/>
            <Feature
            img={feature3}
            name="Personal Wallet"
            description="track your assets in the in-app crypto wallet"
            link_view="/#personalwallet"
            />
            <Feature
            img={feature4}
            name="AIO Dashboard"
            description="track your assets in the in-app crypto wallet"
            link_view="/#aiodashboard"/>
        </div>
    </div>
    </div>
)

export default AllFeatures

const Feature: FunctionComponent<{
    img?: string
    name?: string
    description?: string
    link_view?: string
}> = ({
    img,
    name,
    description,
    link_view,
}) => {
    return (
        <div className="group">
        <div className="flex flex-col w-full bg-primary-500 justify-center items-center gap-6 text-white  group-hover:bg-primary-200 duration-500 h-full">
            <div className="w-3/6 bg-primary-400 rounded-full flex items-center justify-center aspect-square">
            <img 
            src={img}
            className="z-10 relative w-7/12"
            />
            </div>
            <span className="text-2xl font-bold group-hover:text-black duration-700 md:text-sm lg:text-base xl:text-2xl">
                {name}
            </span>
            <span className="text w-9/12 group-hover:text-black duration-700 text-xs xl:text-base">
                {description}
            </span>
        </div>
        <div className="h-16 opacity-0 bg-primary-600 duration-700 w-full group-hover:opacity-100 flex items-center justify-center">
        <Link href={link_view} className="text-xs md:text-base w-9/12 hover:scale-105 duration-300">
            <span className="font-bold text-sm md:text-xs lg:text-base">
        Explore Feature  >
            </span>
                </Link>
        </div>
        </div>
        )
}